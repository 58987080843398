import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { AppLoader } from "../components/app-loader";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Services() {
  const [services, setServices] = useState([]);
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [servicesMedia, setServicesMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "services"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get services
        axios
          .get(
            CONTENT_ROUTES.SERVICES(
              "?order_by=menu_order&order=asc&per_page=100"
            )
          )
          .then((res) => {
            setServices(res.data);

            //Get services media
            let media = [];

            res.data?.map((service, i) => {
              if (service?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(service?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setServicesMedia((prevState) => [...prevState, media[i]]);
                  });
              return service?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - Sakhiwo`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200 md:px-0 px-8"></div>

        {Object.keys(page).length ? (
          <section className="bg-white md:py-16 py-10">
            <div className="container mx-auto md:px-0 px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-4 md:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div>
                  <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                    <h1 className="pb-5 md:text-5xl text-3xl font-semibold">
                      {page?.title?.rendered}
                    </h1>
                    <div className="w-20 border-b-4 border-red-600 mb-5"></div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page?.content?.rendered,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <AppLoader />
        )}

        {Object.keys(services).length ? (
          <section className="bg-white pb-16">
            <div className="container mx-auto md:px-0 px-8">
              <div className="space-y-16">
                {services?.map((service, i) => (
                  <div
                    key={i}
                    className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
                  >
                    <div
                      className={`mt-6 lg:col-span-5 ${
                        i % 2 === 0 ? "lg:col-start-7" : "lg:col-start-1"
                      } lg:row-start-1 lg:mt-0`}
                    >
                      <h3>
                        <NavLink
                          to={`/services/` + service?.slug}
                          className="text-2xl font-semibold text-gray-900 hover:text-red-600"
                        >
                          {service?.title?.rendered}
                        </NavLink>
                      </h3>

                      <p
                        className="mt-2 mb-10"
                        dangerouslySetInnerHTML={{
                          __html: service?.content?.rendered
                            ?.replace(/<h2.*?>*?<\/h2>/g, "")
                            ?.replace(/<p.*?>/g, ""),
                        }}
                      />

                      <NavLink
                        to={`/services/` + service?.slug}
                        className="bg-red-600 px-5 py-2 text-white hover:bg-gray-950 inline-block"
                      >
                        Read more{" "}
                        <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                      </NavLink>
                    </div>
                    <div
                      className={`flex-auto lg:col-span-6 ${
                        i % 2 === 0 ? "lg:col-start-1" : "lg:col-start-7"
                      } lg:row-start-1`}
                    >
                      <div className="overflow-hidden bg-gray-100">
                        <NavLink to={`/services/` + service?.slug}>
                          <img
                            src={
                              servicesMedia?.find(
                                (media) => media?.id === service?.featured_media
                              )?.source_url
                            }
                            alt={service?.title?.rendered}
                            className="object-cover object-center w-full"
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          <AppLoader />
        )}

        {Object.keys(page).length ? (
          <section id="mission-vision" className="bg-white pb-16">
            <div className="mx-auto container md:px-0 px-8">
              <div className="mx-auto max-w-2xl lg:text-center mb-5">
                <h2 className="md:py-5 py-0 text-4xl font-semibold">
                  {page?.acf?.why_sakhiwo_title}
                </h2>
              </div>
              <div className="mx-auto">
                <dl className="grid max-w-xl grid-cols-1 lg:max-w-none lg:grid-cols-2">
                  {page?.acf?.why_sakhiwo_fields?.map((field, i) => (
                    <div
                      key={i}
                      className={`relative md:pl-20 pl-10 ${
                        i % 3 === 0 ? "bg-white" : "md:bg-gray-100"
                      } md:py-10 pr-10`}
                    >
                      <dt className="text-xl font-semibold text-gray-900">
                        <div className="absolute left-0 top-0 flex md:px-5 px-0 py-10 mdpl-10 items-center justify-center text-red-600">
                          0{i + 1}
                        </div>
                        {field.why_sakhiwo_field_title}
                      </dt>
                      <dd className="mt-2 text-base text-gray-600">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: field.why_sakhiwo_field_description,
                          }}
                        />
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </section>
        ) : (
          <AppLoader />
        )}

        {Object.keys(page).length ? (
          <section className="relative isolate overflow-hidden">
            <img
              src={
                pageMedia?.find(
                  (media) =>
                    media.id === page?.acf?.services_page_lower_banner_image
                )?.source_url
              }
              alt={page.title?.rendered}
              className="absolute inset-0 -z-10 h-full w-full object-cover"
            />
            <div className="h-full w-full bg-gray-900/40 px-8 py-48">
              <div className="container mx-auto text-center max-w-2xl">
                <h2 className="md:text-4xl text-3xl text-white mb-10">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: page?.acf?.services_page_lower_banner_title,
                    }}
                  />
                </h2>

                <NavLink
                  to={page?.acf?.services_page_lower_banner_cta.url}
                  className="bg-red-600 px-5 py-2 text-white hover:bg-gray-950 inline-block"
                >
                  {page?.acf?.services_page_lower_banner_cta.title}{" "}
                  <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                </NavLink>
              </div>
            </div>
          </section>
        ) : (
          <AppLoader />
        )}
      </main>
      <Footer />
    </>
  );
}
