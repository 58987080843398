import { useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Careers() {
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "careers"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - Sakhiwo`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200"></div>
        <section className="bg-white md:py-16 pb-10 pt-0">
          <div className="container mx-auto ">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 md:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-4">
                <div className="relative">
                  <img
                    className="h-full w-full object-cover"
                    src={
                      pageMedia?.find(
                        (media) => media?.id === page?.featured_media
                      )?.source_url
                    }
                    alt={page?.title?.rendered}
                  />
                </div>
              </div>
              <div className="md:px-0 px-8">
                <div className="text-base leading-7 text-gray-700">
                  <h1 className="pb-5 md:text-5xl text-3xl font-semibold">
                    Work with us
                  </h1>
                  <div className="w-20 border-b-4 border-red-600 mb-5"></div>
                  <div className="max-w-xl">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page?.content?.rendered?.replace(
                          /<p.*?>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
