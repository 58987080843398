import FormNewsletter from "../components/form-newsletter";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function Newsletter() {
  let { getMetaSnippet } = useUtilities();
  return (
    <>
      <Helmet>
        <title>{`Sign up for our mailing list - Sakhiwo`}</title>
        <meta
          name="description"
          content={`You can sign up for our mailing list for all the latest Sakhiwo news and related information by completing the subscription form.`}
        />
      </Helmet>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200"></div>
        <section className="bg-white md:py-16 py-10">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 md:text-5xl text-3xl font-semibold">
                Sign up for our mailing list
              </h1>
              <div className="w-20 border-b-4 border-red-600 mb-5"></div>
              <div className="md:flex gap-x-10">
                <article className="w-full md:w-6/12">
                  <p className="text-2xl">
                    You can sign up for our mailing list for all the latest
                    Sakhiwo news and related information by completing the
                    subscription form.
                  </p>
                  <p className="mt-4 text-sm text-gray-500">
                    You can unsubscribe from our mailing list at any time using
                    the link in our emails. Learn about how we process your
                    personal data in our{" "}
                    <a className="hover:underline" href="/legal/privacy-policy">
                      privacy&nbsp;policy
                    </a>
                    .
                  </p>
                  <p className="mt-4 text-sm text-gray-500">
                    This form is protected by reCAPTCHA. Google's{" "}
                    <a
                      href="https://policies.google.com/privacy?hl=en-GB"
                      target="_blank"
                      className="hover:underline"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://policies.google.com/terms?hl=en-GB"
                      target="_blank"
                      className="hover:underline"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>{" "}
                    apply.
                  </p>
                </article>
                <div className="w-full md:w-6/12 mt-5 md:mt-0 relative ">
                  <GoogleReCaptchaProvider reCaptchaKey="6LdwfS0qAAAAADL-Y35c0EINFiEnUyVM8Rk0D0dU">
                    <FormNewsletter bgType={`light`} />
                  </GoogleReCaptchaProvider>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
