import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { WEB_ROUTES } from "../routes/web-routes";
import { useMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Projects() {
  const [activeProject, setActiveProject] = useState("all");
  const projectType = useMatch(WEB_ROUTES.PROJECTS_TYPE + "/:type");

  const [projects, setProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [servicesMedia, setProjectsMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "projects"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get projects
        axios
          .get(
            CONTENT_ROUTES.PROJECTS(
              "?order_by=menu_order&order=asc&per_page=100"
            )
          )
          .then((res) => {
            setAllProjects(res.data);

            //Get projects media
            let media = [];

            res.data?.map((project, i) => {
              if (project?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(project?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setProjectsMedia((prevState) => [...prevState, media[i]]);
                  });
              return project?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (activeProject !== "all")
      setProjects(
        allProjects.filter(
          (project) =>
            project?.acf?.project_status?.toLowerCase() === activeProject
        )
      );
    else setProjects(allProjects);
  }, [activeProject, allProjects]);

  useEffect(() => {
    if (projectType?.params?.type && projectType?.params?.type?.length !== 0)
      setActiveProject(projectType?.params?.type);
    else setActiveProject("all");
  }, [projectType]);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - Sakhiwo`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200 md:px-0 px-8"></div>

        <section className="bg-white md:py-16 py-10">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-4 md:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <h1 className="pb-5 md:text-5xl text-3xl font-semibold">
                    {page?.title?.rendered}
                  </h1>
                  <div className="w-20 border-b-4 border-red-600 mb-5"></div>
                </div>
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <div className="max-w-xl">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page?.content?.rendered?.replace(
                          /<p.*?>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-10">
          <div className="container mx-auto md:px-0 px-8 flex">
            <NavLink
              to={WEB_ROUTES.PROJECTS}
              className={`mr-2 md:mr-4 px-5 py-2 cursor-pointer normal-case inline-block ${
                activeProject === "all"
                  ? "bg-red-600 text-white"
                  : "border-red-600 hover:bg-red-600 hover:text-white border-b-[3px]"
              }`}
            >
              All projects
            </NavLink>
            <NavLink
              to={WEB_ROUTES.PROJECTS_TYPE + "/completed"}
              className={`mr-2 md:mr-4 px-5 py-2 cursor-pointer normal-case inline-block ${
                activeProject === "completed"
                  ? "bg-red-600 text-white"
                  : "border-red-600 hover:bg-red-600 hover:text-white border-b-[3px]"
              }`}
            >
              Completed projects
            </NavLink>
            <NavLink
              to={WEB_ROUTES.PROJECTS_TYPE + "/ongoing"}
              className={`mr-2 md:mr-4 px-5 py-2 cursor-pointer normal-case inline-block ${
                activeProject === "ongoing"
                  ? "bg-red-600 text-white"
                  : "border-red-600 hover:bg-red-600 hover:text-white border-b-[3px]"
              }`}
            >
              Ongoing projects
            </NavLink>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="masonry sm:masonry-sm md:masonry-lg ">
              {projects?.map((project, i) => (
                <NavLink key={i} to={`/projects/` + project?.slug}>
                  <div className="relative break-inside mb-[1.5em] group">
                    {/* <img
                      className="h-auto max-w-full"
                      src={project?.image}
                      alt={project?.title}
                    /> */}
                    <div className=" bg-gray-600 group-hover:bg-gray-950 z-10 w-full h-full p-6">
                      <h3 className="text-3xl font-semibold text-white capitalize mb-5 transition-all duration-500 ease-out border-l-[3px] border-gray-600 pl-0 group-hover:pl-4 group-hover:border-red-600">
                        {project?.title?.rendered}
                      </h3>
                      <span className="font-semibold text-white md:mt-0 mt-10 inline-block pl-1">
                        Learn more{" "}
                        <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                      </span>
                    </div>
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
