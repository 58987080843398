import { useState, useContext, useEffect, useCallback } from "react";
import { LoaderContext } from "../contexts/loader";
import { CONTENT_ROUTES } from "../routes/content-routes";
import axios from "axios";
import { useFormValidation } from "./form-validation";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const useForms = () => {
  let { setLoading } = useContext(LoaderContext);
  let { validFormDetails } = useFormValidation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  function addHours(date, hours) {
    const hoursToAdd = hours * 60 * 60 * 1000;
    date.setTime(date.getTime() + hoursToAdd);
    return date;
  }

  const submitContactForm = async (
    formFields,
    formDetails,
    setInvalidFormDetails,
    setFormError,
    setFormSubmitted
  ) => {
    if (
      validFormDetails(
        formFields,
        formDetails,
        setInvalidFormDetails,
        setFormError
      )
    ) {
      setLoading(true);

      if (!executeRecaptcha) {
        setFormError("ReCAPTCHA is not ready. Please try again.");
        return;
      }

      try {
        // Get the reCAPTCHA token
        const recaptchaToken = await executeRecaptcha("contact_form");

        await axios
          .post(CONTENT_ROUTES.SUBMIT_CONTACT_EMAIL(), {
            ...formDetails,
            recaptchaToken,
          })
          .then((res) => {
            setFormSubmitted(true);
          })
          .catch((error) => {
            setFormError(
              error?.response?.data?.error
                ? error?.response?.data?.error
                : error?.message
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error(
          "Error submitting form: ",
          error?.response?.data?.error
            ? error?.response?.data?.error
            : error?.message
        );
        setFormError("Error submitting form. Please try again.");
      }
    }
  };

  const submitNewsletterForm = async (
    formFields,
    formDetails,
    setInvalidFormDetails,
    setFormError,
    setFormSubmitted
  ) => {
    if (
      validFormDetails(
        formFields,
        formDetails,
        setInvalidFormDetails,
        setFormError
      )
    ) {
      setLoading(true);

      if (!executeRecaptcha) {
        setFormError("ReCAPTCHA is not ready. Please try again.");
        return;
      }

      try {
        // Get the reCAPTCHA token
        const recaptchaToken = await executeRecaptcha("newsletter_form");

        await axios
          .post(CONTENT_ROUTES.SUBMIT_NEWSLETTER(), {
            ...formDetails,
            recaptchaToken,
          })
          .then((res) => {
            setFormSubmitted(true);
          })
          .catch((error) => {
            setFormError(
              error?.response?.data?.error
                ? error?.response?.data?.error
                : error?.message
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error(
          "Error submitting form: ",
          error?.response?.data?.error
            ? error?.response?.data?.error
            : error?.message
        );
        setFormError("Error submitting form. Please try again.");
      }
    }
  };

  return { submitContactForm, submitNewsletterForm, addHours };
};
